const {fr} = require("date-fns/locale");
module.exports = Object.assign({dateFnsLocale: fr}, {
  "chapo_length_number": 559,
  "date_format": {
    "fr": "d MMMM yyyy"
  },
  "didomi_api_key": "2127e597-3d3f-4aa2-9727-c8126f86298c",
  "notifications_service_endpoint": "https://apim.keolis.com/SIK/000/notification",
  "search_token": "f8c120d7e2327d2cada38d6b1d8bc1",
  "v3_share_label": {
    "fr": "Je partage"
  },
  "hour_format": {
    "fr": "HH:mm"
  },
  "notifications_service_import_file_endpoint": "https://apim.keolis.com/SIK/000/file/import HTTP/1.1",
  "piano_site_id": "639195",
  "site_name": "Bordeaux Metropole Mobilites",
  "v3_social_networks": [
    "RFjst8BPSMGh170edrU4Rg",
    "DGagPrnzRmO4hOIdToy_YQ",
    "VclKeq4WRJiVoRrDMtZ-1g",
    "eTSmONy_TWqUmbxhIf6U1w"
  ],
  "facebook_link": "https://www.facebook.com/sharer/sharer.php?u=",
  "google_tag_manager_id": "GTM-TZMTBNH9",
  "meta_description": {
    "fr": "Opérateur privé de transport public de voyageurs, engagé pour une mobilité durable et connectée."
  },
  "notifications_service_token": "2d5392f4a9404b2690515b92a3eadc93",
  "google_analytics_key": "",
  "subsidiary_logo": {
    "fr": {
      "path": "/102760/1701262352-kb2m_couleur-1.svg",
      "format": "svg",
      "size": 9495,
      "alt": "Logo Keolis Bordeaux Métropole Mobilités",
      "title": "Logo Keolis Bordeaux Métropole Mobilités",
      "width": 343,
      "height": 113
    }
  },
  "twitter_link": "https://twitter.com/intent/tweet?url=",
  "linkedin_link": "https://www.linkedin.com/sharing/share-offsite/?url=",
  "subsidiary_reduced_logo": {
    "fr": {
      "path": "/102760/1724157971-logo_k_bleu-size5.svg",
      "format": "svg",
      "size": 754,
      "alt": "Logo Keolis",
      "title": "Keolis",
      "width": 256,
      "height": 256
    }
  },
  "home_page_title": {
    "fr": "Accueil"
  },
  "mailto_addresse": "mailto:someone@example.com",
  "share_buttons_title": {
    "fr": "Partager"
  },
  "transport_mode_title": {
    "fr": "Modes de transport"
  },
  "facebook_label": {
    "fr": "Facebook"
  },
  "token_google_site_verification": "",
  "twitter_label": {
    "fr": "X"
  },
  "linkedin_label": {
    "fr": "LinkedIn"
  },
  "mailto_label": {
    "fr": "Partager par mail"
  },
  "download_label": {
    "fr": "Télécharger"
  },
  "updated_at": "2024-09-20T09:22:46.381+02:00",
  "created_at": "2021-04-20T17:40:04.593+02:00",
  "locale": "fr",
  "locales": [
    "fr"
  ],
  "siteUrl": "https://www.kb2m.fr/"
})